import React, {Component} from "react"
import PropTypes from 'prop-types'
import * as API from "../../api"
import FilesView from "../../components/filesView"
import TagsView from "../../components/tagsView"

import {
  ArrayInput,
  AutocompleteArrayInput,
	BooleanInput,
  CardActions,
	ChipField,
	Create,
	Datagrid,
	DateField,
	DateInput,
	DisabledInput,
	Edit,
	EditButton,
	FileField,
	FileInput,
  Filter,
  ImageField,
	Labeled,
  List,
	LongTextInput,
	ReferenceArrayField,
	ReferenceArrayInput,
	SelectArrayInput,
	SelectInput,
	SimpleForm,
  SimpleFormIterator,
	TextField,
	TextInput
} from 'react-admin';

import restProvider from 'ra-data-simple-rest';

import defaultListOptions from "../shared/defaultListOptions"
const SearchFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
)

export const BackgroundList = (props) => (
  <List {...props} filters={<SearchFilter />} {...defaultListOptions()}>
      <Datagrid>
          <TextField source="id" />
          <TextField source="name" />
          <ImageField source="preview.src" label="Icon" />

          <ReferenceArrayField filter={{ type: 'background' }} reference="categories" source="categories">
            <Datagrid>
              <ChipField source="name" />
            </Datagrid>
          </ReferenceArrayField>

          <EditButton basePath="/backgrounds" />
      </Datagrid>
  </List>
);

const BackgroundName = ({ record }) => {
    return <span>{record ? `"${record.name}"` : ''}</span>;
};

export const BackgroundEdit = (props) => (
    <Edit title={<BackgroundName />} {...props}>
        <SimpleForm>
            <DisabledInput source="id" />
            <ImageField source="preview.src" label="Icon" />
            <TextInput source="name" />
            <FilesView title="Background Files" />
            
            <TagsView />

            <ReferenceArrayInput reference="categories" source="categories" label="Categories" filter={{ type: 'background' }}>
              <SelectArrayInput>
                <ChipField source="name" />
              </SelectArrayInput>
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
);

export const BackgroundCreate = (props) => (
    <Create title="Create a Background" {...props}>
        <SimpleForm>
            <TextInput source="name" />

            <FilesView title="Background Files" />
          
            <TagsView />

            <ReferenceArrayInput reference="categories" source="categories" label="Categories" filter={{ type: 'background' }}>
              <SelectArrayInput>
                <ChipField source="name" />
              </SelectArrayInput>
            </ReferenceArrayInput>
        </SimpleForm>
    </Create>
);

