// in addUploadFeature.js
/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadFeature = requestHandler => (type, resource, params) => {
    if (['CREATE', 'UPDATE'].includes(type) && ["animations", "backgrounds", "categories"].includes(resource)) {
      // console.log("UPDATE request", type, resource, params)
        // notice that following condition can be true only when `<ImageInput source="pictures" />` component has parameter `multiple={true}`
        // if parameter `multiple` is false, then data.pictures is not an array, but single object
        if (params.data.files && params.data.files.length) {
            // only freshly dropped files are instance of File
            const formerFiles = params.data.files.filter(p => !(p.rawFile instanceof File));
            const newFiles = params.data.files.filter(p => p.rawFile instanceof File);

            return Promise.all(newFiles.map(convertFileToBase64))
                .then(base64Files => base64Files.map((file64, index) => ({
                    src: file64,
                    title: `${newFiles[index].title}`,
                })))
                .then(transformedNewFiles => requestHandler(type, resource, {
                    ...params,
                    data: {
                        ...params.data,
                        files: [...transformedNewFiles, ...formerFiles],
                    },
                }));
        }
    }

  // console.log("Normal request", type, resource, params)
    // for other request types and resources, fall back to the default request handler
    return requestHandler(type, resource, params);
};

export default addUploadFeature;
