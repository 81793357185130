import React, {Component} from "react"
import PropTypes from 'prop-types'

import {
	FileField,
	FileInput,
  ImageField
} from 'react-admin';

const isImage = src => src.match(/(jp.?g|png|gif)$/)

const Preview = props => {
  // console.log("PREVIEW", props, isImage(props.record.src))

  return (
    isImage(props.record.src) ?
      <ImageField {...props} /> :
      <FileField {...props} />
  )
}
const FilesView = props => ( 
  <FileInput source="files" label={props.title} multiple={true}>
    <Preview source="src" title="title" />
  </FileInput>)

export default FilesView

