// in src/authProvider.js
import { AUTH_CHECK, AUTH_LOGIN, AUTH_LOGOUT, fetchUtils } from "react-admin";

import { getApiURI } from "../api"

export const authenticatedClient = (url, options = {}) => {
  if(!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  
  const token = localStorage.getItem("token");
  
  options.headers.set("Authorization", `Bearer ${token}`);

  return fetchUtils.fetchJson(url, options);
}

export default (type, params) => {
  // console.log("API URL for type, params", type, params)
  if(type === AUTH_LOGIN) {
    const { username, password } = params;
    // console.log("In login: API URL", getApiURI() + "/authenticate")
    const request = new Request(getApiURI() + "/authenticate", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    })
    
    return fetch(request)
          .then(response => {
              if (response.status < 200 || response.status >= 300) {
                  throw new Error(response.statusText);
              }

              return response.json();
          })
          .then(({ token }) => {
              localStorage.setItem("token", token);
          });
  }

  if(type === AUTH_CHECK) {
    return localStorage.getItem("token") ?
      Promise.resolve() :
      Promise.reject(); // eslint-disable-line
  }

  if(type === AUTH_LOGOUT) {
    localStorage.removeItem("token");

    return Promise.resolve();
  }

  return Promise.resolve();
}
