import React, { Component } from 'react';
import PropTypes from 'prop-types';
import path from 'path';

import {
  ArrayInput,
  BooleanInput,
  ChipField,
  FileField,
  FileInput,
  FunctionField,
  GET_LIST,
  ImageField,
  Labeled,
  Query,
  ReferenceInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  withDataProvider
} from 'react-admin';

const label = props => {
  const files = props.parentRecord
    ? props.parentRecord.files
        .map(file => path.basename(file.src))
        .filter(file => file.match(/.json$/))
    : [];

  const index =
    props.id && Number.parseInt(props.id.match(/backgrounded\[(\d+)\]/)[1]) + 1;

  return files[index - 1];
};

const LabeledSelectInput = props => {
  const { choices, parentRecord, ...newProps } = props;

  return (
    <SelectInput
      {...newProps}
      label={label(props)}
      allowEmpty={true}
      choices={choices}
      optionText="name"
      resettable
    />
  );
};

class Backgrounded extends Component {
  static propTypes = {
    dataProvider: PropTypes.func.isRequired
  };

  state = {
    backgrounds: []
  };

  componentDidMount() {
    this.props
      .dataProvider(GET_LIST, 'backgrounds', {
        pagination: { page: 1, perPage: 500 },
        sort: { field: 'name', order: 'ASC' }
      })
      .then(result => {
        this.setState({ backgrounds: result.data });
      });
  }

  choices = state =>
    state.backgrounds.map(background => ({
      id: background.id,
      name: background.name
    }));

  renderSelect = (props, state) => (
    <ArrayInput {...props} source="backgrounded">
      <SimpleFormIterator disableAdd={true} disableRemove={true}>
        <LabeledSelectInput
          choices={this.choices(state)}
          parentRecord={props.record}
        />
        } />
      </SimpleFormIterator>
    </ArrayInput>
  );

  render() {
    const { dataProvider, dispatch, ...newProps } = this.props;

    if (!this.state.backgrounds.length) return '';

    return this.renderSelect(newProps, this.state);
  }
}

export default withDataProvider(Backgrounded);
