import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import {
  AnimationList,
  AnimationEdit,
  AnimationCreate
} from "./screens/animations";

import {
  BackgroundList,
  BackgroundEdit,
  BackgroundCreate
} from "./screens/backgrounds";

import {
  CategoryList,
  CategoryEdit,
  CategoryCreate
} from "./screens/categories";

import { Admin, Resource } from "react-admin";
import authProvider from "./lib/authProvider"
import dataProvider from "./lib/dataProvider"

const App = () => {
  return (
    <Admin authProvider={authProvider} dataProvider={dataProvider}>
      <Resource
        name="animations"
        options={{ label: 'Designs' }}
        list={AnimationList}
        edit={AnimationEdit}
        create={AnimationCreate}
      />
      <Resource
        name="backgrounds"
        list={BackgroundList}
        edit={BackgroundEdit}
        create={BackgroundCreate}
      />
      <Resource
        name="categories"
        list={CategoryList}
        edit={CategoryEdit}
        create={CategoryCreate}
      />
    </Admin>
  );
};

export default App;
