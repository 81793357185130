import addUploadFeature from "./addUploadFeature";
import { authenticatedClient } from "./authProvider"
import { getApiURI } from "../api"
import restProvider from "ra-data-simple-rest"

export const apiUrl = getApiURI()

export const apiBase = () => `${apiUrl}`

export default addUploadFeature(restProvider(apiBase(), authenticatedClient), apiBase())

