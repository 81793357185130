import React from 'react';
import Button from '@material-ui/core/Button';

import {
  ArrayInput,
  BooleanInput,
  CardActions,
  ChipField,
  Create,
  Datagrid,
  DisabledInput,
  Edit,
  EditButton,
  Filter,
  FormDataConsumer,
  ImageField,
  List,
  ReferenceArrayField,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput
} from "react-admin";

import { ColorInput } from "react-admin-color-input"

import TagsView from "../../components/tagsView"

import FilesView from "../../components/filesView"

import "react-tagsinput/react-tagsinput.css" // If using WebPack and style-loader.

import defaultListOptions from "../shared/defaultListOptions";

export * from "./edit"

const zeroRange = n => [...Array(n).keys()];

const upTo = (n, callback) => zeroRange(n).map(callback);

const arrayToChoices = (arr, names = undefined) => {
  // console.log("Calling array to Choices on", arr)
  const val = arr.reduce(
    (ac, v, i) => ac.concat({ id: v, name: names ? names[i] : `${v}` }),
    []
  );
  // console.log("Converted", val)
  return val;
};

const designCount = props => ''; // props.files.filter(this.isDesign).length

const LabeledBooleanInput = props => {
  const index = Number.parseInt(props.id.match(/animated\[(\d+)\]/)[1]) + 1;

  const label = 'data' + index + '.json';
  return <BooleanInput {...props} label={label} />;
};

const downloadConfig = data => event => {
  console.log('DOWNLOAD DATA', data);
  event.preventDefault();
  return fetch('/animations/' + data.id + '/config.json')
    .then(resp => resp.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = 'config.json';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(() => alert('error downloading config'));
};

// SELECT OPTIONS
const poseTypes = arrayToChoices(
  ['full', 'half', 'three_quarters', 'sitting', 'any'],
  ['Full body', 'Half body', '75% body', 'Sitting', 'Any']
);

const outlineTypes = arrayToChoices([
  'normal',
  'glow',
  'motion',
  'fire',
  'neon',
  'artistic',
  'straight',
  'shadow',
  'rainbow',
  'radiant'
]);

const SearchFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const AnimationList = props => (
  <List {...props} filters={<SearchFilter />} {...defaultListOptions()} title="Designs">
    <Datagrid>
      <ImageField source="preview.src" label="Icon" />
      <TextField source="id" />
      <TextField source="name" />

      <ReferenceArrayField
        filter={{ type: 'animation' }}
        reference="categories"
        source="categories"
      >
        <Datagrid>
          <ChipField source="name" />
        </Datagrid>
      </ReferenceArrayField>

      <EditButton basePath="/animations" />
    </Datagrid>
  </List>
);

const AnimationName = ({ record }) => {
  return <span>{record ? `"${record.name}"` : ''}</span>;
};

export const AnimationCreate = props => (
  <Create
    title="Create a Lottie Animation"
    {...props}
    encType="multipart/form-data"
  >
    <SimpleForm>
      <FilesView title="Lottie files" />
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);
