const slugify = str =>
  str
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-")
    .replace(/&/g, "-and-")
    .replace(/[^\w\-]+/g, "") // eslint-disable-line
    .replace(/\-\-+/g, "-"); // eslint-disable-line

export const animationId = ({ category, name }) =>
  slugify(category) + "__" + slugify(name);

export const animationPath = ({ category, name }) =>
  "/animations/" + animationId({ category, name });

export const animationDownloadPath = ({ category, name }) =>
  "/animations/" + animationId({ category, name }) + "/download";

export const animationEditPath = ({ category, name }) =>
  "/animations/" + animationId({ category, name }) + "/edit";

export const animationDestroyPath = ({ category, name }) =>
  "/animations/" + animationId({ category, name });
//
// export const animationPath = props => (
//     "/api/categories/" +
//     encodeURIComponent(props.category) +
//     "/names/" +
//     encodeURIComponent(props.name) +
//     "/files")

export const animationConfigPath = ({ category, name }) =>
  "/api/categories/" +
  encodeURIComponent(category) +
  "/names/" +
  encodeURIComponent(name) +
  "/config";

export const validAnimation = props =>
  props && ["category", "name"].every(field => props[field]);

const fetchAnimation = props =>
  fetch(animationPath(props)).then(files => files.json());

export const show = props =>
  validAnimation(props) ? fetchAnimation(props) : Promise.resolve([]);

export const create = ({ category, name, file }) => {
  const data = new FormData();

  data.append("file", file);

  data.append("category", category);

  data.append("name", name);

  return fetch("/upload", {
    method: "POST",
    body: data
  }).then(res => res.json());
};

export const update = ({ category, name, preview }) => {
  const data = new FormData();

  data.append("category", category);
  data.append("name", name);
  data.append("preview", preview);

  fetch(animationConfigPath({ category, name }), {
    method: "POST",
    body: data
  }).then(res => res.json());
};

// filterHidden = files => (files || []).filter(f => !f.match(/(^|\/)\./))
//
// fileList = state => this.filterHidden(state.files).sort()
//
const isIP = str => (str && str.match(/\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/))

const domainMatch = (domain, uri) => uri.includes(domain)

export const getApiURI = (relativePath = "") => {
  const defaultURI = location.protocol // eslint-disable-line
    .concat("//")
    .concat(location.hostname + ":" + location.port) // eslint-disable-line
    .concat(location.port) // eslint-disable-line
    .concat("/api")

  const configURI = process.env.REACT_APP_API_URI

  // console.log("Default URI", defaultURI)
  // console.log("Config URI", configURI)
  // console.log("Is IP", isIP(defaultURI))
  // console.log("Matches", domainMatch(location.hostname, configURI)) // eslint-disable-line

  // This makes the configured API url a bit pointless, but for now, we
  // need current domain to match to avoid cors issues
  const api = (
    !configURI ||
    isIP(defaultURI) ||
    !domainMatch(location.hostname, configURI) ? // eslint-disable-line
      defaultURI :
      configURI)

  console.log("Generated API URL", `${api}${relativePath}`)

  return `${api}${relativePath}`;
};
