import React, {Component} from "react"
import PropTypes from 'prop-types'
import path from 'path'

import {
  ArrayInput,
  BooleanInput,
	FileField,
	FileInput,
  ImageField,
  SimpleFormIterator

} from 'react-admin';

const LabeledBooleanInput = props => {
  const {parentRecord, ...otherProps} = props
  const files = parentRecord ?
    parentRecord
      .files
      .map(file => path.basename(file.src))
      .filter(file => file.match(/.json$/)) :
    []

  const index = Number.parseInt(props.id.match(/animated\[(\d+)\]/)[1]) + 1;

  const label = files[index - 1]

  return <BooleanInput {...otherProps} label={label} />;
};


const Animated = props => {
  return <ArrayInput {...props} label="Animated" source="animated">
          <SimpleFormIterator disableAdd={true} disableRemove={true}>
            <LabeledBooleanInput parentRecord={props.record}/>
          </SimpleFormIterator>
        </ArrayInput>}

export default Animated
