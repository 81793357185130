import React from 'react';
import Button from '@material-ui/core/Button';

import {
  ArrayInput,
  BooleanInput,
  CardActions,
  ChipField,
  Create,
  Datagrid,
  DisabledInput,
  Edit,
  EditButton,
  Filter,
  FormDataConsumer,
  FormTab,
  FunctionField,
  ImageField,
  Labeled,
  List,
  ReferenceArrayField,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput
} from 'react-admin';

import { Field } from 'redux-form'

import { ColorInput } from 'react-admin-color-input';

import TagsView from '../../../components/tagsView';

import FilesView from '../../../components/filesView';

import 'react-tagsinput/react-tagsinput.css'; // If using WebPack and style-loader.

import defaultListOptions from '../../shared/defaultListOptions';

import Animated from './animated';
import Backgrounded from './backgrounded';

const zeroRange = n => [...Array(n).keys()];

const upTo = (n, callback) => zeroRange(n).map(callback);

const arrayToChoices = (arr, names = undefined) => {
  // console.log("Calling array to Choices on", arr)
  const val = arr.reduce(
    (ac, v, i) => ac.concat({ id: v, name: names ? names[i] : `${v}` }),
    []
  );
  // console.log("Converted", val)
  return val;
};

const designCount = props => ''; // props.files.filter(this.isDesign).length
const downloadConfig = data => event => {
  console.log('DOWNLOAD DATA', data);
  event.preventDefault();
  return fetch('/animations/' + data.id + '/config.json')
    .then(resp => resp.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = 'config.json';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(() => alert('error downloading config'));
};

// SELECT OPTIONS
const poseTypes = arrayToChoices(
  ['full', 'half', 'three_quarters', 'sitting', 'any'],
  ['Full body', 'Half body', '75% body', 'Sitting', 'Any']
);

const outlineTypes = arrayToChoices([
  'normal',
  'glow',
  'motion',
  'fire',
  'neon',
  'artistic',
  'straight',
  'shadow',
  'rainbow',
  'radiant'
]);

// Markup Helpers (react-admin provides parameters to children
// which prevents just dropping in html
const lineBreak = () => (
  <FunctionField addLabel={false} render={() => <br />} />
);
const description = msg => (
  <FunctionField
    addLabel={false}
    render={() => <div style={{ color: 'blue' }}>{msg}</div>}
  />
);

const AnimationName = ({ record }) => {
  return <span>{record ? `"${record.name}"` : ''}</span>;
};

const AnimationEditActions = ({ basePath, data, resource }) => (
  <CardActions>
    <Button color="primary" onClick={downloadConfig(data)}>
      Download Config
    </Button>
  </CardActions>
);

export const AnimationEdit = props => (
  <Edit title={<AnimationName />} actions={<AnimationEditActions />} {...props}>
    <TabbedForm encType="multipart/form-data">
      <FormTab label="Info">
        <DisabledInput source="id" />
        <ImageField source="preview.src" label="Icon" />
        <TextInput source="name" />
        <BooleanInput source="test" label="Test Mode" />
        {lineBreak()}
        <ReferenceArrayInput
          reference="categories"
          source="categories"
          label="Categories: Choose one or more"
          filter={{ type: 'animation' }}
        >
          <SelectArrayInput>
            <ChipField source="name" />
          </SelectArrayInput>
        </ReferenceArrayInput>

        {lineBreak()}
        {description(
          'Use a custom background that is already uploaded to the background section'
        )}
        <BooleanInput source="background_replace" label="Background Replace" />

        {lineBreak()}
        {description('Design is transparent. In development')}
        <BooleanInput source="is_transparent" label="Design is transparent" />

        {lineBreak()}
        {description(
          'Color will be used as default background if set. In development'
        )}
        <TextInput
          source="color_background"
          label="Default color background"
          resettable
        />

        {/* //      Not developed yet
  //      <FunctionField render={<br />} />
  //      <div style="color:blue">If lottie has layers with apng sequence or movie files</div>          
  //      <BooleanInput
  //        source="contains_apng_layers"
  //        label="Contains APNG Layers"
  //        title="Ff lottie has layers with apng sequence, look for it in folder."
      /> */}
        {lineBreak()}
        {description(
          'Design layer type: lottie is lotite animation, middle is PNG static design, foreground not developed, background not developed'
        )}
        <SelectInput
          source="design_layer_type"
          label="Design Layer Type"
          title="lottie lets lottie control it, middle is PNG static design "
          choices={arrayToChoices([
            'lottie',
            'background',
            'foreground',
            'middle'
          ])}
        />

        {lineBreak()}
        {description(
          ' Placement photo align inside design frame. C - center, T - top, B - bottom, L - left, R - right. For example TR - top right corner, BL - bottom left'
        )}
        <SelectInput
          source="replacement_config"
          choices={[
            { id: 'CC', name: 'CC' },
            { id: 'CL', name: 'CL' },
            { id: 'CR', name: 'CR' },
            { id: 'TC', name: 'TC' },
            { id: 'TL', name: 'TL' },
            { id: 'TR', name: 'TR' },
            { id: 'BC', name: 'BC' },
            { id: 'BL', name: 'BL' },
            { id: 'BR', name: 'BR' }
          ]}
        />
        {lineBreak()}
        {description(
          'Allow Design Color Replace: Let users have option to change colors on design'
        )}
        <BooleanInput
          source="allow_design_color_replacement"
          label="Allow Design Color Replacement"
          title=""
          defaultValue={true}
        />

        {lineBreak()}
        {description(
          'Choose Design type: photo, video (we currentlyu have no video designs)'
        )}
        <SelectInput
          source="design_type"
          label="Design Type"
          title="Options: photo, video (we currentlyu have no video designs"
          choices={arrayToChoices(['photo', 'video'])}
        />

        {/* // Edge blending is currently be used on inputs      
  //   <br>
  //   <div style="color:blue">Blends the edge of the person.  Currently we are using this everywhere</div> 
  //      <BooleanInput
  //        source="edge_blending"
  //        label="Edge Blending"
  //        title="Only used when we are replacing the background."
  //      /> */}

        {/* // We have not yet developed glow, but working on it 
  //   <br>
  //   <div style="color:blue">Apply a glow effect to all vector shapes</div>                   
  //      <BooleanInput
  //        source="glow"
  //        label="Glow"
  //        title="Whether to apply an edge glow."
  //      /> */}

        {lineBreak()}
        {description(
          'Middle layer: If this is on, places a middle layer color between person and background and has transparency setting'
        )}
        <ColorInput
          source="m_layer_overlay_color"
          label="M Layer Overlay Color"
          title="Adds a middle layer overlay color that the app lets user change. Leave blank for none"
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => (formData.m_layer_overlay_color && (
            <SelectInput
              {...rest}
              source="m_layer_overlay_opacity"
              label="M Layer Overlay Opacity"
              choices={arrayToChoices(zeroRange(100).map(i => i + 1))} />))}
        </FormDataConsumer>

        {/* //      <br>
  //      <div style="color:blue">Music: If selected, the system will look for a .mp3 file in the uploaded files</div>
  // Music has not been developed yet          
  //      <BooleanInput
  //        source="music"
  //        label="Music"
  //        title="Look for music mp3 in folder."
  //      /> */}

        {lineBreak()}
        {description(
          'Pose Type: defines if the design was made for a specific pose or any pose will work'
        )}
        <SelectInput
          source="pose_type"
          label="Pose Type"
          title="This defines if the design was made for a specific pose or any"
          choices={poseTypes}
        />

        {
          //
          // <BooleanInput  source="outline_animation" label="Outline Animation" title="Body outline stroke - leave blank for no outline" />
          //
          // <ArrayInput source="outline_colors">
          //   <SimpleFormIterator>
          //     <ColorInput />
          //   </SimpleFormIterator>
          // </ArrayInput>
          //
          // <ArrayInput source="outline_widths">
          //   <SimpleFormIterator disableAdd={true} disableRemove={true} >
          //     <SelectInput choices={arrayToChoices(zeroRange(10))} />
          //   </SimpleFormIterator>
          // </ArrayInput>
        }

        {/* //      <SelectInput
  //        source="photo_inputs_required"
  //        label="Photo Inputs Required"
  //        title="If design requires user to take more than 1 photo -- no current designs use this"
  //        choices={arrayToChoices(zeroRange(100))}
  //      /> */}

        {lineBreak()}
        {description('Choose a repeat value. Defaults to 3')}
        <SelectInput
          source="repeat"
          label="Repeat"
          title="Number of times to repeat animation"
          choices={arrayToChoices(zeroRange(10))}
        />

        {lineBreak()}
        {description(
          'Do we want the user to be able to scale this design by hand? like PNG designs'
        )}
        <BooleanInput
          source="scalable"
          label="Scalable"
          title="For designs like PNG wings that we let user scale size by hand"
        />

        {lineBreak()}
        {description(
          ' Shadow: creates a virtual shadow from the body with 3 variables '
        )}
        <BooleanInput
          source="shadow"
          label="Body Shadow"
          title="To create a virtual shadow from the body"
        />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.shadow && (
              <React.Fragment>
                <SelectInput
                  {...rest}
                  source="shadow_blur"
                  label="Shadow Blur"
                  choices={arrayToChoices(zeroRange(999))}
                />
                <SelectInput
                  {...rest}
                  source="shadow_horizontal"
                  label="Shadow Horizontal"
                  title="left/right"
                  choices={arrayToChoices(zeroRange(999))}
                />
                <SelectInput
                  {...rest}
                  source="shadow_bend"
                  label="Shadow Bend"
                  choices={arrayToChoices(zeroRange(999))}
                />
              </React.Fragment>
            )
          }
        </FormDataConsumer>

        {lineBreak()}
        {description(
          ' Silhouette: if on, place a color with or without transparency over the body.  '
        )}
        <BooleanInput
          source="silhouette"
          label="Body Color Overlay (Silhouette)"
          title="If we want to place a color with or without transparency over the body."
        />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.silhouette && (
              <React.Fragment>
                <ColorInput
                  {...rest}
                  source="silhouette_color"
                  label="Silhouette Color"
                  title="silhouette color"
                />
                <SelectInput
                  {...rest}
                  source="silhouette_opacity"
                  label="Silhouette Opacity"
                  choices={arrayToChoices(zeroRange(100).map(i => i + 1))}
                />
              </React.Fragment>
            )
          }
        </FormDataConsumer>

        <TagsView />
      </FormTab>
      <FormTab label="Files">
        {description(
          ' Upload all lottie files and thumbnail file as thumbnail.jpg (200x200) '
        )}
        <FilesView title="Design Files" />

        {description(
          ' Animated: Is this design animated or still? If off, Still.  '
        )}

        <Animated />
        <Backgrounded />
      </FormTab>

      <FormTab label="Outlines">
        {lineBreak()}
        {description(
          ' Outlines: If on, choose type, and if regular up to 3 with colors and width '
        )}

        <React.Fragment>
          <SelectInput source="outlines.type" label="Type" choices={outlineTypes} allowEmpty={true} />
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource, ...rest }) => {
              const type = formData.outlines.type

              if(!type)
                return ""

              const normal = type == "normal"

              return <React.Fragment>
                <BooleanInput {...rest} source="outlines.animated" label="Animated" />

                <ArrayInput source="outlines.lines">
                  {normal ?
                  <React.Fragment>
                    <ColorInput 
                      source="outlines.lines[0].color"
                      {...rest} label="Color 1" />

                    <SelectInput
                      source="outlines.lines[0].width"
                      {...rest}
                      label="Width 1"
                      choices={arrayToChoices(zeroRange(10))}
                    />

<ColorInput 
                      source="outlines.lines[1].color"
                      {...rest} label="Color 2" />

                    <SelectInput
                      source="outlines.lines[1].width"
                      {...rest}
                      label="Width 2"
                      choices={arrayToChoices(zeroRange(10))}
                    />

                    <ColorInput 
                      source="outlines.lines[2].color"
                      {...rest} label="Color 3" />

                    <SelectInput
                      source="outlines.lines[2].width"
                      {...rest}
                      label="Width 3"
                      choices={arrayToChoices(zeroRange(10))}
                    />



                  </React.Fragment> :

                  <React.Fragment>
                    <ColorInput 
                      source="outlines.lines[0].color"
                      {...rest} label="Color" />

                    <SelectInput
                      source="outlines.lines[0].width"
                      {...rest}
                      label="Width"
                      choices={arrayToChoices(zeroRange(10))}
                    />
                  </React.Fragment> }
                </ArrayInput>
              </React.Fragment>
            }}
          </FormDataConsumer>
        </React.Fragment>
      </FormTab>
    </TabbedForm>
  </Edit>
);
