import React, {Component} from "react"
import PropTypes from 'prop-types'

import {
  ArrayInput,
  SimpleFormIterator,
  TextInput
} from 'react-admin';

const TagsView = props => ( 
  <ArrayInput source="tags" title="Separate with spaces">
    <SimpleFormIterator>
      <TextInput source="name" />
    </SimpleFormIterator>
  </ArrayInput>)

export default TagsView 

