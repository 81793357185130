import React, {Component} from "react"
import PropTypes from 'prop-types'
import FilesView from "../../components/filesView"

import {List, ImageField, Datagrid, Edit, Filter, Create, SimpleForm, SelectInput, DateField, TextField, EditButton, DisabledInput, TextInput, LongTextInput, DateInput } from 'react-admin';

import defaultListOptions from "../shared/defaultListOptions"

const typeChoices = [
  {id: "animation", name: "animation"},
  {id: "background", name: "background"}
]

const SearchFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
)


export const CategoryList = props => (
    <List {...props} {...defaultListOptions()} filters={<SearchFilter />}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />

            <TextField source="type" />

            <ImageField source="preview.src" label="Icon" />

            <EditButton basePath="/categories" />
        </Datagrid>
    </List>
)

const CategoryName = props => (
  <span>{ props.record ?  `"${props.record.name}"` : "" }</span>
)

export const CategoryEdit = props => (
    <Edit title={<CategoryName />} {...props}>
        <SimpleForm>
            <DisabledInput source="id" />
            <ImageField source="preview.src" label="Icon" />
            <SelectInput  source="type" label="Category Type" choices={typeChoices} />
            <TextInput source="name" />
            <FilesView title="Media" />
        </SimpleForm>
    </Edit>
);

export const CategoryCreate = props => (
  <Create title="Create a Category" {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <SelectInput  source="type" label="Category Type" choices={typeChoices} />
      <FilesView title="Media" />
    </SimpleForm>
  </Create>)

